.overlay-grid {
    position: relative;
}

.middle {
    transition: 0.5s ease;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9999;
}

.text {
    font-size: 50px;
    opacity: 0.5;
    transform: scale(5);
}
