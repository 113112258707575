.businessIntel {
    overflow-y: auto !important;
    height: 88vh;
}

.businessIntel-card {
    text-align: center;
}

.businessIntel-image {
    padding: 5px;
    margin: auto;
    display: block;
    width: 75%;
}

.businessIntel-card-title {
    text-align: center;
    color: black;
}

.businessIntel-card-deck a {
    height: 180px;
    width: 300px;
}
