.documentViewDatePicker .react-datepicker-wrapper,
.documentViewDatePicker .react-datepicker__input-container {
    display: block;
    width: 100%;
}
.documentViewDatePicker .react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

#select-supplier {
    width: 100%;
    margin-right: 14px;
}

#select-match {
    width: 25%;
}

.react-pdf__Page__canvas {
    display: grid;
    place-items: center;
}

.react-pdf__Page__textContent {
    display: grid;
    place-items: center;
}

.pdf-buttons {
    justify-content: space-between;
    display: flex;
}

.document-list-view-default-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
