@media all and (min-width: 480px) {
    .Login {
        padding: 5%;
        background-color: white;
        border: 1px solid #3cb4e5;
        max-width: 480px;
        min-width: 480px;
        position: center;
    }

    .Login form {
        margin: 0 auto;
        max-width: 320px;
    }
}
.Login form a {
    margin-bottom: 15px;
    display: block;
    font-size: 14px;
}

.Login .LoaderButton {
    background-color: #00a894;
    border-radius: 20px;
}
