@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

.ag-theme-alpine {
    @include ag-theme-alpine(
        (
            grid-size: 4px,
            font-size: 13px,
            header-height: 30px,
            selected-row-background-color: #effaff,
            row-hover-color: #effaff,
            odd-row-background-color: #f8f8f8,
            font-family: (
                'Noto Sans',
                sans-serif,
            ),
        )
    );
}
