.notification {
    height: 82vh;
}

.unscheduled-notification-card {
    overflow-y: auto !important;
    overflow-x: hidden;
    height: 55vh;
}

.unscheduled-notification-card p {
    font-size: 11px;
}

.notification-card {
    overflow-y: auto !important;
    height: 20vh;
}
