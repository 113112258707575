@media all and (min-width: 480px) {
    .ResetPassword {
        padding: 5%;
        background-color: white;
        border: 1px solid #3cb4e5;
        margin: 5% 35% auto;
        max-width: 480px;
        min-width: 480px;
        position: center;
    }

    .ResetPassword form {
        margin: 0 auto;
        max-width: 320px;
    }

    .ResetPassword .success {
        max-width: 400px;
    }
}

.ResetPassword .success {
    margin: 0 auto;
    text-align: center;
}
.ResetPassword .success .glyphicon {
    color: grey;
    font-size: 30px;
    margin-bottom: 30px;
}

.ResetPassword .LoaderButton {
    background-color: #00a894;
    border-radius: 20px;
    margin-top: 30px;
}
