.headerRow {
    display: flex;
}

.react-datepicker-popper {
    z-index: 5000;
}

.headerControlDatePicker {
    text-align: center;
    font-size: 18px;
    color: #333;
    background-color: transparent;
    border-bottom: 2px solid #e3b924;
    width: 100%;
}
.headerControlDatePicker input {
    border: none;
    text-align: center;
}
