.phrase-tabpanel .MuiPaper-root {
    box-shadow: none;
}

.phrase-tabpanel .MuiCardHeader-root {
    padding: 1px;
}

.phrase-tabpanel .MuiCardContent-root {
    padding-left: 0;
    padding-right: 8px;
}

.phrase-tabpanel > .MuiBox-root,
.phrase-tabpanel .MuiPaper-root,
.phrase-tabpanel .MuiCardContent-root,
.phrase-tabpanel .MuiCardContent-root > .MuiGrid-container {
    height: 100%;
}
