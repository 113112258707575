.App {
    padding: 0;
    margin: 0;
    height: 100vh;
}

.BlueBackground {
    background-image: url('libs/images/Dots.png');
    background-color: #104c8f;
    display: grid;
    place-items: center;
}

.App .authLinks {
    background-color: #007cbb;
    margin-left: 10px;
    font-size: medium;
    white-space: nowrap;
    border: 2px solid #007cbb;
    border-radius: 20px;
}

.App .navbar {
    background: #104c8f;
}

.App .navLinks-image {
    padding-right: 20px;
    width: 55px;
}

.content {
    padding: 1%;
}
.menu {
    background: #104c8f;
    height: 100vh;
    width: 20%;
    transition: all 0.25s ease;
    transform: translateX(0);
}
.menu a {
    color: #fff;
    text-decoration: none;
    display: block;
}
.menu ul {
    padding: 0;
    margin: 0;
}
.menu ul li {
    background-color: #104c8f;
    font-size: 16px;
    border-bottom: 1px solid #fff;
    transition: all 0.25s ease;
    animation: fadeInRight 0.25s ease forwards;
}
.menu ul li:hover {
    opacity: 0.8;
    transition: all 0.25s ease;
    background: #000;
    cursor: pointer;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}
