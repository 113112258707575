.spinnerContainer {
    margin: 10px auto;
    display: grid;
    place-items: center;
}

.loadingIcon {
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
