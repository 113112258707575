.cell-new {
    border-color: #007cbb;
}

.cell-edited {
    font-weight: bold;
    background-color: #f0fffd;
}

.cell-error {
    font-weight: bold;
    background-color: #de8787;
}

.odd-cell-non-editable {
    background-color: #dcdfe2;
}

.even-cell-non-editable {
    background-color: #edeff0;
}

.menu-container {
    height: 50vh;
    width: 75vw;
}

.custom-header-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.custom-header-sort {
    margin-left: 4px;
    cursor: pointer;
    float: left;
}

#custom-header-menu-icon {
    float: right;
}

.ag-react-container {
    display: flex;
    width: 100%;
}
