.Home {
    position: relative;
}
.Home .lander {
    padding: 40px 0;
    text-align: center;
    background-color: white;
    display: block;
    max-width: 480px;
    min-width: 480px;
}

.Home .lander h1 {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

.Home .lander div {
    padding-top: 20px;
}

.Home .lander .authLinks {
    background-color: #007cbb;
    border: none;
    border-radius: 20px;
    display: block;
    margin: 20px 45px;
    position: relative;
}
.Home .lander div a:first-child {
    background-color: #00a894;
    border: 2px solid #00a894;
    position: relative;
}

.Home .lander div a:nth-child(2) {
    background-color: white;
    border: 2px solid #00a894;
    color: #00a894;
}
