.comment-box-footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.comment-box-length {
    display: inline-block;
    text-align: right;
}

.comment-list-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.comment-list {
    margin-bottom: 2%;
}

.comment-list-text {
    white-space: break-spaces;
}
