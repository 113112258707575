@media all and (min-width: 480px) {
    .ChangeEmail {
        padding: 60px 0;
        background-color: white;
        margin-top: 40px;
        margin-left: 500px;
        margin-right: 500px;
        border: 1px solid #3cb4e5;
        border-radius: 5px;
    }

    .ChangeEmail form {
        margin: 0 auto;
        max-width: 320px;
    }
}
