.app-streaming-card-body {
    color: black;
}

.app-streaming-card {
    cursor: pointer;
}

.app-streaming-image {
    padding: 5px;
    margin: auto;
    display: block;
    width: 50%;
}

.app-streaming-card-deck a {
    height: 200px;
    width: 300px;
}
