@tailwind base;
@tailwind components;
@tailwind utilities;
@import './themes';
@import './buttons';
@import './forms';
@import './grids';
@import './headers';
@import './inputs';
@import './layout';
@import './modals';
@import './navs';
@import './panels';
@import './tabs';
@import './text';
