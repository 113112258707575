.action-bar {
    height: 30px;
    background-color: #f8f8f8;
    border: 1px solid #babfc6;
    border-bottom: none;
}

.ag-theme-alpine .ag-row {
    font-size: 13px;
    font-family: 'Noto Sans', sans-serif;
    border-top-style: solid;
}

.full-size-item {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-wrap-text.cell-span.ag-cell-value {
    border-color: #babfc7;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom-style: solid;
}

.cell-span {
    background-color: #fff;
}
