.approval-workflow-modal {
    height: 100%;
}

.approval-workflow-approvers-cell {
    display: flex;
    align-items: center;
    gap: 5px;
}

.ap-automation-approval-workflows-body {
    height: 60vh;
}

.approval-workflow-update-button {
    display: flex;
    align-items: center;
    gap: 1px;
}

.full-width-grid {
    display: block;
}

.ag-grid-overflow .ag-cell {
    overflow: visible !important;
    height: auto !important;
    border: none;
}

.ag-grid-overflow .ag-row {
    overflow: visible !important;
}

.ag-grid-overflow .ag-root {
    overflow: visible !important;
}

.ag-grid-overflow .ag-root-wrapper {
    overflow: visible !important;
}

.ag-grid-overflow .ag-body-viewport {
    overflow: visible !important;
}

.ag-grid-overflow .ag-center-cols-viewport {
    overflow: visible !important;
}

.ag-grid-overflow .ag-center-cols-clipper {
    overflow: visible !important;
}

.ag-grid-overflow .basic-select {
    z-index: auto;
    width: 100%;
}

.ag-grid-overflow .ag-body {
    position: inherit;
    height: 100% !important;
}

.ag-grid-overflow .ag-body-container {
    min-height: 82px;
}

.ag-grid-overflow .ag-cell-value {
    width: 100%;
    overflow: visible !important;
}

.ag-grid-overflow .ag-cell-wrap-text {
    word-break: keep-all;
}

.create-workflow-approvers {
    flex-grow: 1.4;
}

.ag-grid-overflow .select__menu-list {
    max-height: 150px;
}

.approval-workflow-button-group {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
