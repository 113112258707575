.analytics-datepicker-group {
    display: flex;
    gap: 10px;
    justify-content: center;
    padding-bottom: 10px;
}

.analytics-list-group-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.analytics-list-group-list {
    display: flex;
    justify-content: center;
}
.analytics-ap-automation-graphs-line-one {
    display: flex;
}
