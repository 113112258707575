.ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
}

.ag-theme-alpine .custom-tooltip {
    position: absolute;
    pointer-events: none;
    transition: opacity 1s;
}

.ag-theme-alpine .custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
}

.ag-theme-alpine .ag-cell {
    line-height: 25px;
}

.grid-group-1 {
    background-color: #f69333;
    font-weight: bold;
    color: white;
}

.grid-group-2 {
    background-color: #00a894;
    font-weight: bold;
    color: white;
}

.grid-group-3 {
    background-color: #007cbb;
    font-weight: bold;
    color: white;
}

.grid-group-4 {
    background-color: #f3b231;
    font-weight: bold;
    color: white;
}

.bar-chart-tooltip {
    padding: 15px;
    border: 1px solid cornflowerblue;
    background-color: #9dd9f2;
}

.bar-chart-tooltip p:first-of-type {
    font-weight: bold;
}

.bar-chart-tooltip p {
    margin: 5px;
    white-space: nowrap;
}
