@media all and (min-width: 480px) {
    .Signup {
        padding: 5%;
        background-color: white;
        border-radius: 30px;
        margin: 5% 35%;
        border: 1px solid #3cb4e5;
        max-width: 480px;
        min-width: 480px;
        position: center;
    }

    .Signup form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.Signup form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
}

.Signup .LoaderButton {
    background-color: #00a894;
    border: 2px solid #00a894;
    border-radius: 20px;
}
